import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./css/HelpModal.css";
import example from "../images/example.png";
const HelpModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="How to play" id="helpModal">
      <Box className="helpModal" onClick={handleClose}>
        <Box className="scrollable-content">
          <Typography id="helpModel Title" variant="h6" component="h2" className="title">
            How to play
          </Typography>
          <Typography id="helpModel Description" className="description">
            <img src={example} alt="Description Image" className="description-image" />
            Your goal is to guess the correct within 5 tries from the list of characters shown. To choose a character,
            click the characters from the character list above.<br></br> <br></br> Colors will indicate if the
            charateristic of the character you selected a correct. Red will indicate wrong guess while green will
            indicate right guess. <br></br> <br></br>Good luck and enjoy! <br></br> <br></br> (Click anywhere to close){" "}
            <br></br> <br></br>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default HelpModal;
