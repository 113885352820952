import React, { useEffect } from "react";
import "./css/Game.css";
import GameTitle from "./TitleInfo";
import Pompom from "../images/pompomQuestion.png";
import { characterList } from "./CharacterInfo";
import Table from "./Table";
import { useState } from "react";
import WinModal from "./WinModal";
import LoseModal from "./LoseModal";
import CharacterSearch from "./CharacterSearch";

const MAXTURN = 5;

const Game = () => {
  const [turn, setTurn] = useState(0);
  const [correctCharacter, setCorrectCharacter] = useState(null);
  const [guessHistory, setGuessHistory] = useState([]);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [animated, setAnimated] = useState(false);

  const [scoreStreak, setScoreStreak] = useState(0);
  const [restartGame, setRestartGame] = useState(false);
  const resetScoreStreak = () => {
    setScoreStreak(0);
  };

  // Pick a random character from the list
  useEffect(() => {
    if (!correctCharacter) {
      setCorrectCharacter(characterList[Math.floor(Math.random() * characterList.length)]);
    }
    if (turn >= MAXTURN && !isCorrect) {
      resetScoreStreak();
      setIsGameOver(true);
    }
    if (restartGame) {
      setRestartGame(false);
    }
  }, [correctCharacter, turn, isCorrect, restartGame]);

  const handleCharacterButtonClick = (characterInfo) => {
    setAnimated(true);
    guessHistory[turn] = characterInfo;
    let newTurn = turn + 1;
    setTurn(newTurn);

    setTimeout(() => {
      setAnimated(false);
    }, 2000);

    // Now handle if the character choice is correct
    if (characterInfo.name === correctCharacter.name) {
      setIsCorrect(true);
      setScoreStreak(scoreStreak + 1);
    }
  };

  const restartGameHandle = () => {
    setRestartGame(true);
    setIsCorrect(false);
    setIsGameOver(false);
    setGuessHistory([]);
    setTurn(0);
    setCorrectCharacter(characterList[Math.floor(Math.random() * characterList.length)]);
  };

  return (
    <div className="game-container">
      <GameTitle title="Honkai Star Rail Wordle" imageSrc={Pompom} author="Krispy"></GameTitle>
      <div className="scorestreak">Scorestreak: {scoreStreak}</div>
      <h2>Character List</h2>

      <CharacterSearch
        characterList={characterList}
        handleCharacterButtonClick={handleCharacterButtonClick}
        restartGame={restartGame}
      ></CharacterSearch>

      <Table data={guessHistory} answer={correctCharacter} restartGame={restartGame} />
      {isCorrect && !animated && <WinModal onRestart={restartGameHandle}></WinModal>}
      {isGameOver && !animated && (
        <LoseModal correctCharacter={correctCharacter} onRestart={restartGameHandle}></LoseModal>
      )}
    </div>
  );
};

export default Game;
