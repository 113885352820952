import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./css/CharacterSearch.css";

const CharacterSearch = ({ handleCharacterButtonClick, characterList, restartGame }) => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [highlightedCharacter, setHighlightedCharacter] = useState(null);
  const [selectedCharactersList, setSelectedCharactersList] = useState([]);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const inputRef = useRef(null);
  const getOptionLabel = (option) => option?.name;

  const getOptionDisabled = (option) => {
    return selectedCharactersList.some((selected) => selected.name === option?.name);
  };

  const handleChange = (event, newValue) => {
    if (newValue == null) {
      return;
    }

    if (!getOptionDisabled(newValue)) {
      setSelectedCharacter(newValue);
      handleCharacterButtonClick(newValue);
      if (newValue) {
        setSelectedCharactersList((prevList) => [...prevList, newValue]);
      }
      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.blur();
      }
    }
  };

  useEffect(() => {
    if (restartGame) {
      setSelectedCharactersList([]);
      setSelectedCharacter(null);
    }
  }, [restartGame]);

  return (
    <div className="character-search">
      <Autocomplete
        id="character-search"
        options={characterList}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        open={autocompleteOpen}
        onOpen={() => setAutocompleteOpen(true)}
        onClose={() => setAutocompleteOpen(false)}
        onHighlightChange={(e, value) => setHighlightedCharacter(value)}
        sx={{ width: 300 }}
        value={selectedCharacter}
        onChange={handleChange}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              label="Character search"
              value={selectedCharacter ? selectedCharacter.name : ""}
              inputRef={inputRef}
            />
          </div>
        )}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              className={`character-option ${getOptionDisabled(option) ? "disabled-option" : ""} ${
                highlightedCharacter?.name === option?.name ? "highlighted" : ""
              }`}
            >
              <img
                src={option && option.imageSrc}
                alt={option && option.name}
                className={`character-option-image ${getOptionDisabled(option) ? "disabled-image" : ""}`}
              />
              {option && option.name}
            </li>
          );
        }}
      />
    </div>
  );
};

export default CharacterSearch;
