import React from "react";
import "./css/WinModal.css";

const WinModal = ({ onRestart }) => {
  return (
    <div className="win-modal-overlay">
      <div className="win-modal">
        <h2>Congratulations! You Won!</h2>
        <button onClick={onRestart}>Restart</button>
      </div>
    </div>
  );
};

export default WinModal;
