import React from "react";
import "./css/Table.css"; // Import the CSS file for styling
import TableRow from "./TableRow";

const Table = ({ data, answer, restartGame }) => {
  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr className="character-row">
            <th className="header-cell">Name</th>
            <th className="header-cell">Image</th>
            <th className="header-cell">Path</th>
            <th className="header-cell">Type</th>
            <th className="header-cell">Region</th>
            <th className="header-cell">Trace #1</th>
            <th className="header-cell">Trace #2</th>
          </tr>
        </thead>
        <tbody>
          <TableRow data={data[0]} answer={answer} restartGame={restartGame}></TableRow>
          <TableRow data={data[1]} answer={answer} restartGame={restartGame}></TableRow>
          <TableRow data={data[2]} answer={answer} restartGame={restartGame}></TableRow>
          <TableRow data={data[3]} answer={answer} restartGame={restartGame}></TableRow>
          <TableRow data={data[4]} answer={answer} restartGame={restartGame}></TableRow>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
