import React from "react";
import "./App.css";
import CenterBox from "./components/CenterBox";
import { createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import NavBar from "./components/NavBar";
import { StyledEngineProvider } from "@mui/material/styles";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <NavBar></NavBar>
        <CenterBox />
      </QueryClientProvider>
    </StyledEngineProvider>
  );
}

export default App;
