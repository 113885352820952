import React from "react";
import "./css/Title.css";

const GameTitle = ({ title, imageSrc, author }) => {
  return (
    <div id="title container" className="title-container">
      <img className="title-image" src={imageSrc} alt="Honkai Star Rail World Title Logo" />

      <div className="custom-title">{title} </div>
    </div>
  );
};
export default GameTitle;
