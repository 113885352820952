import React from "react";
import "../App.css";
import Game from "./Game";
const CenterBox = () => {
  return (
    <div className="center-box">
      <Game></Game>
    </div>
  );
};

export default CenterBox;
