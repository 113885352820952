const importAll = (r) => {
  const pathToImageMap = {};
  r.keys().forEach((path) => {
    const imageSrc = r(path);
    pathToImageMap[path] = imageSrc;
  });

  return pathToImageMap;
};

const characterImages = importAll(require.context("../images/characters", false, /\.(png|jpe?g|svg)$/));
const pathImages = importAll(require.context("../images/paths", false, /\.(png|jpe?g|svg|webp)$/));
const regionImages = importAll(require.context("../images/regions", false, /\.(png|jpe?g|svg|webp)$/));
const typeImages = importAll(require.context("../images/types", false, /\.(png|jpe?g|svg|webp)$/));
const traceImages = importAll(require.context("../images/traces", false, /\.(png|jpe?g|svg|webp)$/));

const characterList = [];

const pathMap = {
  ABUNDANCE: "./Abundance.webp",
  DESTRUCTION: "./Destruction.webp",
  ERUDITION: "./Erudition.webp",
  HARMONY: "./Harmony.webp",
  HUNT: "./Hunt.webp",
  NIHILITY: "./Nihility.webp",
  PRESERVATION: "./Preservation.webp",
};

const regionMap = {
  ASTRAL_EXPRESS: "./Astral_Express.png",
  BELEBOG: "./Belebog.png",
  COSMIC: "./Cosmic.png",
  HSS: "./Herta_Space_Station.png",
  IPC: "./IPC.png",
  PENACONY: "./Penacony.png",
  STELLARON_HUNTERS: "./Stellaron_Hunters.png",
  XIANZHOU: "./Xianzhou.png",
};

const traceMap = {
  ANCIENT_PART: "./Ancient_Part.webp",
  ARROW_OF_THE_BEAST_HUNTER: "./Arrow_of_the_Beast_Hunter.webp",
  ARTIFEX_MODULE: "./Artifex_Module.webp",
  DREAM_COLLECTION_COMPONENT: "./Dream_Collection_Component.webp",
  ENDURANCE_OF_BRONZE: "./Endurance_of_Bronze.webp",
  EXTINGUISHED_CORE: "./Extinguished_Core.webp",
  FIERY_SPIRIT: "./Fiery_Spirit.webp",
  HARMONIC_TUNE: "./Harmonic_Tune.webp",
  IMMORTAL_SCIONETTE: "./Immortal_Scionette.webp",
  ASCENDANT_DEBRIS: "./Item_Ascendant_Debris.webp",
  KEY_OF_INSPIRATION: "./Key_of_Inspiration.webp",
  NAIL_OF_THE_APE: "./Nail of the Ape.webp",
  OBSIDIAN_OF_DREAD: "./Obsidian_of_Dread.webp",
  SEED_OF_ABUNDANCE: "./Seed_of_Abundance.webp",
  SHATTERED_BLADE: "./Shattered_Blade.webp",
  SILVERMANE_BADGE: "./Silvermane_Badge.webp",
  SUPPRESSING_EDICT: "./Suppressing Edict.webp",
  THIEF_INSTINCT: "./Thief_instinct.webp",
  SCATTERED_STARDUST: "./Scattered_Stardust.webp",
  TATTERS_OF_THOUGHT: "./Tatters_of_Thought.webp",
  FIRMAMENT_NOTE: "./Firmament_Note.webp",
  METEORIC_BULLET: "./Meteoric_Bullet.webp",
  ALIEN_TREE_SEED: "./Alien_Tree_Seed.webp",
  BORISIN_TEETH: "./Borisin_Teeth.webp",
  ROUGH_SKETCH: "./Rough_Sketch.webp",
};

const typeMap = {
  FIRE: "./Type_Fire.webp",
  ICE: "./Type_Ice.webp",
  IMAGINARY: "./Type_Imaginary.webp",
  LIGHTNING: "./Type_Lightning.webp",
  PHYSICAL: "./Type_Physical.webp",
  QUANTUM: "./Type_Quantum.webp",
  WIND: "./Type_Wind.webp",
};

let index = 0;

// Load characters
const loadCharacters = () => {
  characterList.push({
    key: index++,
    name: "Trailblazer (Fire)",
    imageSrc: characterImages["./Trailblazer_Fire.png"],
    path: pathImages[pathMap.PRESERVATION],
    type: typeImages[typeMap.FIRE],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages[traceMap.THIEF_INSTINCT],
    traceTwo: traceImages[traceMap.ENDURANCE_OF_BRONZE],
  });
  characterList.push({
    key: index++,
    name: "Trailblazer (Physcial)",
    imageSrc: characterImages["./Trailblazer_Physical.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.PHYSICAL],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages[traceMap.THIEF_INSTINCT],
    traceTwo: traceImages[traceMap.SHATTERED_BLADE],
  });
  characterList.push({
    key: index++,
    name: "Trailblazer (Imaginary)",
    imageSrc: characterImages["./Trailblazer_Imaginary.png"],
    path: pathImages[pathMap.HARMONY],
    type: typeImages[typeMap.IMAGINARY],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages[traceMap.THIEF_INSTINCT],
    traceTwo: traceImages[traceMap.FIRMAMENT_NOTE],
  });

  characterList.push({
    key: index++,
    name: "Acheron",
    imageSrc: characterImages["./Acheron.png"],
    path: pathImages[pathMap.NIHILITY],
    type: typeImages[typeMap.LIGHTNING],
    region: regionImages[regionMap.COSMIC],
    traceOne: traceImages[traceMap.DREAM_COLLECTION_COMPONENT],
    traceTwo: traceImages[traceMap.FIERY_SPIRIT],
  });
  characterList.push({
    key: index++,
    name: "Argenti",
    imageSrc: characterImages["./Argenti.png"],
    path: pathImages[pathMap.ERUDITION],
    type: typeImages[typeMap.PHYSICAL],
    region: regionImages[regionMap.COSMIC],
    traceOne: traceImages[traceMap.EXTINGUISHED_CORE],
    traceTwo: traceImages[traceMap.KEY_OF_INSPIRATION],
  });
  characterList.push({
    key: index++,
    name: "Aventurine",
    imageSrc: characterImages["./Aventurine.png"],
    path: pathImages[pathMap.PRESERVATION],
    type: typeImages[typeMap.IMAGINARY],
    region: regionImages[regionMap.PENACONY],
    traceOne: traceImages[traceMap.TATTERS_OF_THOUGHT],
    traceTwo: traceImages[traceMap.SCATTERED_STARDUST],
  });
  characterList.push({
    key: index++,
    name: "Bailu",
    imageSrc: characterImages["./Bailu.png"],
    path: pathImages[pathMap.ABUNDANCE],
    type: typeImages[typeMap.LIGHTNING],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages[traceMap.EXTINGUISHED_CORE],
    traceTwo: traceImages[traceMap.SEED_OF_ABUNDANCE],
  });
  characterList.push({
    key: index++,
    name: "Black Swan",
    imageSrc: characterImages["./Black Swan.png"],
    path: pathImages[pathMap.NIHILITY],
    type: typeImages[typeMap.WIND],
    region: regionImages[regionMap.PENACONY],
    traceOne: traceImages[traceMap.EXTINGUISHED_CORE],
    traceTwo: traceImages[traceMap.FIERY_SPIRIT],
  });
  characterList.push({
    key: index++,
    name: "Blade",
    imageSrc: characterImages["./blade.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.WIND],
    region: regionImages[regionMap.STELLARON_HUNTERS],
    traceOne: traceImages[traceMap.IMMORTAL_SCIONETTE],
    traceTwo: traceImages[traceMap.SHATTERED_BLADE],
  });
  characterList.push({
    key: index++,
    name: "Boothill",
    imageSrc: characterImages["./Boothill.png"],
    path: pathImages[pathMap.HUNT],
    type: typeImages[typeMap.PHYSICAL],
    region: regionImages[regionMap.COSMIC],
    traceOne: traceImages[traceMap.METEORIC_BULLET],
    traceTwo: traceImages[traceMap.TATTERS_OF_THOUGHT],
  });
  characterList.push({
    key: index++,
    name: "Bronya",
    imageSrc: characterImages["./Bronya.png"],
    path: pathImages[pathMap.HARMONY],
    type: typeImages[typeMap.WIND],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages[traceMap.SILVERMANE_BADGE],
    traceTwo: traceImages[traceMap.HARMONIC_TUNE],
  });
  characterList.push({
    key: index++,
    name: "Clara",
    imageSrc: characterImages["./Clara.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.PHYSICAL],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages[traceMap.ANCIENT_PART],
    traceTwo: traceImages[traceMap.SHATTERED_BLADE],
  });

  characterList.push({
    key: index++,
    name: "Dan Heng - IL",
    imageSrc: characterImages["./DHIL.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.IMAGINARY],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages[traceMap.IMMORTAL_SCIONETTE],
    traceTwo: traceImages[traceMap.SHATTERED_BLADE],
  });

  characterList.push({
    key: index++,
    name: "Dr. Ratio",
    imageSrc: characterImages["./Dr. Ratio.png"],
    path: pathImages[pathMap.HUNT],
    type: typeImages[typeMap.IMAGINARY],
    region: regionImages[regionMap.COSMIC],
    traceOne: traceImages[traceMap.THIEF_INSTINCT],
    traceTwo: traceImages[traceMap.ARROW_OF_THE_BEAST_HUNTER],
  });

  characterList.push({
    key: index++,
    name: "Fu Xuan",
    imageSrc: characterImages["./Fu Xuan.png"],
    path: pathImages[pathMap.PRESERVATION],
    type: typeImages[typeMap.QUANTUM],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages[traceMap.ENDURANCE_OF_BRONZE],
    traceTwo: traceImages[traceMap.ARTIFEX_MODULE],
  });

  characterList.push({
    key: index++,
    name: "Gepard",
    imageSrc: characterImages["./Gepard.png"],
    path: pathImages["./Preservation.webp"],
    type: typeImages["./Type_Ice.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Silvermane_Badge.webp"],
    traceTwo: traceImages["./Endurance_of_Bronze.webp"],
  });

  characterList.push({
    key: index++,
    name: "Himeko",
    imageSrc: characterImages["./Himeko.png"],
    path: pathImages["./Erudition.webp"],
    type: typeImages["./Type_Fire.webp"],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages["./Extinguished_Core.webp"],
    traceTwo: traceImages["./Key_of_Inspiration.webp"],
  });

  characterList.push({
    key: index++,
    name: "Huohuo",
    imageSrc: characterImages["./Huo Huo.png"],
    path: pathImages["./Abundance.webp"],
    type: typeImages["./Type_Wind.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Immortal_Scionette.webp"],
    traceTwo: traceImages["./Seed_of_Abundance.webp"],
  });

  characterList.push({
    key: index++,
    name: "Jing Yuan",
    imageSrc: characterImages["./Jing_Yuan.png"],
    path: pathImages["./Erudition.webp"],
    type: typeImages["./Type_Lightning.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Immortal_Scionette.webp"],
    traceTwo: traceImages["./Key_of_Inspiration.webp"],
  });

  characterList.push({
    key: index++,
    name: "Jingliu",
    imageSrc: characterImages["./Jingliu.png"],
    path: pathImages["./Destruction.webp"],
    type: typeImages["./Type_Ice.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Immortal_Scionette.webp"],
    traceTwo: traceImages["./Shattered_Blade.webp"],
  });

  characterList.push({
    key: index++,
    name: "Kafka",
    imageSrc: characterImages["./Kafka.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Lightning.webp"],
    region: regionImages[regionMap.STELLARON_HUNTERS],
    traceOne: traceImages["./Thief_instinct.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });

  characterList.push({
    key: index++,
    name: "Luocha",
    imageSrc: characterImages["./Luocha.png"],
    path: pathImages["./Abundance.webp"],
    type: typeImages["./Type_Imaginary.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Artifex_Module.webp"],
    traceTwo: traceImages["./Seed_of_Abundance.webp"],
  });

  characterList.push({
    key: index++,
    name: "Robin",
    imageSrc: characterImages["./Robin.png"],
    path: pathImages[pathMap.HARMONY],
    type: typeImages[typeMap.PHYSICAL],
    region: regionImages[regionMap.PENACONY],
    traceOne: traceImages[traceMap.DREAM_COLLECTION_COMPONENT],
    traceTwo: traceImages[traceMap.FIRMAMENT_NOTE],
  });

  characterList.push({
    key: index++,
    name: "Ruan Mei",
    imageSrc: characterImages["./Ruan Mei.png"],
    path: pathImages[pathMap.HARMONY],
    type: typeImages[typeMap.ICE],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages[traceMap.IMMORTAL_SCIONETTE],
    traceTwo: traceImages[traceMap.HARMONIC_TUNE],
  });

  characterList.push({
    key: index++,
    name: "Seele",
    imageSrc: characterImages["./Seele.png"],
    path: pathImages["./Hunt.webp"],
    type: typeImages["./Type_Quantum.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Thief_instinct.webp"],
    traceTwo: traceImages["./Arrow_of_the_Beast_Hunter.webp"],
  });

  characterList.push({
    key: index++,
    name: "Silver Wolf",
    imageSrc: characterImages["./Silver_Wolf.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Quantum.webp"],
    region: regionImages[regionMap.STELLARON_HUNTERS],
    traceOne: traceImages["./Ancient_Part.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });

  characterList.push({
    key: index++,
    name: "Sparkle",
    imageSrc: characterImages["./Sparkle.png"],
    path: pathImages[pathMap.HARMONY],
    type: typeImages[typeMap.QUANTUM],
    region: regionImages[regionMap.PENACONY],
    traceOne: traceImages[traceMap.TATTERS_OF_THOUGHT],
    traceTwo: traceImages[traceMap.FIRMAMENT_NOTE],
  });

  characterList.push({
    key: index++,
    name: "Topaz",
    imageSrc: characterImages["./Topaz.png"],
    path: pathImages["./Hunt.webp"],
    type: typeImages["./Type_Fire.webp"],
    region: regionImages[regionMap.IPC],
    traceOne: traceImages["./Silvermane_Badge.webp"],
    traceTwo: traceImages["./Arrow_of_the_Beast_Hunter.webp"],
  });

  characterList.push({
    key: index++,
    name: "Welt",
    imageSrc: characterImages["./Welt.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Imaginary.webp"],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages["./Silvermane_Badge.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });

  characterList.push({
    key: index++,
    name: "Yanqing",
    imageSrc: characterImages["./Yanqing.png"],
    path: pathImages["./Hunt.webp"],
    type: typeImages["./Type_Ice.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Thief_instinct.webp"],
    traceTwo: traceImages["./Arrow_of_the_Beast_Hunter.webp"],
  });

  characterList.push({
    key: index++,
    name: "Arlan",
    imageSrc: characterImages["./Arlan.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.LIGHTNING],
    region: regionImages[regionMap.HSS],
    traceOne: traceImages[traceMap.EXTINGUISHED_CORE],
    traceTwo: traceImages[traceMap.SHATTERED_BLADE],
  });

  characterList.push({
    key: index++,
    name: "Asta",
    imageSrc: characterImages["./Asta.png"],
    path: pathImages[pathMap.HARMONY],
    type: typeImages[typeMap.FIRE],
    region: regionImages[regionMap.HSS],
    traceOne: traceImages[traceMap.SILVERMANE_BADGE],
    traceTwo: traceImages[traceMap.HARMONIC_TUNE],
  });

  characterList.push({
    key: index++,
    name: "Dan Heng",
    imageSrc: characterImages["./Dan_Heng.png"],
    path: pathImages[pathMap.HUNT],
    type: typeImages[typeMap.WIND],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages[traceMap.EXTINGUISHED_CORE],
    traceTwo: traceImages[traceMap.ARROW_OF_THE_BEAST_HUNTER],
  });

  characterList.push({
    key: index++,
    name: "Gallagher",
    imageSrc: characterImages["./Gallagher.png"],
    path: pathImages[pathMap.ABUNDANCE],
    type: typeImages[typeMap.FIRE],
    region: regionImages[regionMap.PENACONY],
    traceOne: traceImages[traceMap.DREAM_COLLECTION_COMPONENT],
    traceTwo: traceImages[traceMap.ALIEN_TREE_SEED],
  });

  characterList.push({
    key: index++,
    name: "Guinaifen",
    imageSrc: characterImages["./Guinaifen.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Fire.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Artifex_Module.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });

  characterList.push({
    key: index++,
    name: "Hanya",
    imageSrc: characterImages["./Hanya.png"],
    path: pathImages["./Harmony.webp"],
    type: typeImages["./Type_Physical.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Artifex_Module.webp"],
    traceTwo: traceImages["./Harmonic_Tune.webp"],
  });

  characterList.push({
    key: index++,
    name: "Herta",
    imageSrc: characterImages["./Herta.png"],
    path: pathImages["./Erudition.webp"],
    type: typeImages["./Type_Ice.webp"],
    region: regionImages[regionMap.HSS],
    traceOne: traceImages["./Extinguished_Core.webp"],
    traceTwo: traceImages["./Key_of_Inspiration.webp"],
  });

  characterList.push({
    key: index++,
    name: "Hook",
    imageSrc: characterImages["./Hook.png"],
    path: pathImages["./Destruction.webp"],
    type: typeImages["./Type_Fire.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Ancient_Part.webp"],
    traceTwo: traceImages["./Shattered_Blade.webp"],
  });

  characterList.push({
    key: index++,
    name: "Luka",
    imageSrc: characterImages["./Luka.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Physical.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Ancient_Part.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });

  characterList.push({
    key: index++,
    name: "Lynx",
    imageSrc: characterImages["./Lynx.png"],
    path: pathImages["./Abundance.webp"],
    type: typeImages["./Type_Quantum.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Extinguished_Core.webp"],
    traceTwo: traceImages["./Seed_of_Abundance.webp"],
  });

  characterList.push({
    key: index++,
    name: "March 7th",
    imageSrc: characterImages["./March_7.png"],
    path: pathImages["./Preservation.webp"],
    type: typeImages["./Type_Ice.webp"],
    region: regionImages[regionMap.ASTRAL_EXPRESS],
    traceOne: traceImages["./Thief_instinct.webp"],
    traceTwo: traceImages["./Endurance_of_Bronze.webp"],
  });

  characterList.push({
    key: index++,
    name: "Misha",
    imageSrc: characterImages["./Misha.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.ICE],
    region: regionImages[regionMap.PENACONY],
    traceOne: traceImages[traceMap.DREAM_COLLECTION_COMPONENT],
    traceTwo: traceImages[traceMap.BORISIN_TEETH],
  });

  characterList.push({
    key: index++,
    name: "Natasha",
    imageSrc: characterImages["./Natasha.png"],
    path: pathImages["./Abundance.webp"],
    type: typeImages["./Type_Physical.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Ancient_Part.webp"],
    traceTwo: traceImages["./Seed_of_Abundance.webp"],
  });
  characterList.push({
    key: index++,
    name: "Pela",
    imageSrc: characterImages["./Pela.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Ice.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Extinguished_Core.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });

  characterList.push({
    key: index++,
    name: "Qingque",
    imageSrc: characterImages["./Qingque.png"],
    path: pathImages["./Erudition.webp"],
    type: typeImages["./Type_Quantum.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Thief_instinct.webp"],
    traceTwo: traceImages["./Key_of_Inspiration.webp"],
  });
  characterList.push({
    key: index++,
    name: "Sampo",
    imageSrc: characterImages["./Sampo.png"],
    path: pathImages["./Nihility.webp"],
    type: typeImages["./Type_Wind.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Ancient_Part.webp"],
    traceTwo: traceImages["./Obsidian_of_Dread.webp"],
  });
  characterList.push({
    key: index++,
    name: "Serval",
    imageSrc: characterImages["./Serval.png"],
    path: pathImages["./Erudition.webp"],
    type: typeImages["./Type_Lightning.webp"],
    region: regionImages[regionMap.BELEBOG],
    traceOne: traceImages["./Silvermane_Badge.webp"],
    traceTwo: traceImages["./Key_of_Inspiration.webp"],
  });
  characterList.push({
    key: index++,
    name: "Sushang",
    imageSrc: characterImages["./Sushang.png"],
    path: pathImages["./Hunt.webp"],
    type: typeImages["./Type_Physical.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Artifex_Module.webp"],
    traceTwo: traceImages["./Arrow_of_the_Beast_Hunter.webp"],
  });
  characterList.push({
    key: index++,
    name: "Tingyun",
    imageSrc: characterImages["./Tingyun.png"],
    path: pathImages["./Harmony.webp"],
    type: typeImages["./Type_Lightning.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Immortal_Scionette.webp"],
    traceTwo: traceImages["./Harmonic_Tune.webp"],
  });

  characterList.push({
    key: index++,
    name: "Xueyi",
    imageSrc: characterImages["./Xueyi.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.QUANTUM],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages[traceMap.EXTINGUISHED_CORE],
    traceTwo: traceImages[traceMap.SHATTERED_BLADE],
  });

  characterList.push({
    key: index++,
    name: "Yukong",
    imageSrc: characterImages["./Yukong.png"],
    path: pathImages["./Harmony.webp"],
    type: typeImages["./Type_Imaginary.webp"],
    region: regionImages[regionMap.XIANZHOU],
    traceOne: traceImages["./Artifex_Module.webp"],
    traceTwo: traceImages["./Harmonic_Tune.webp"],
  });

  // NEW CHARACTERS

  characterList.push({
    key: index++,
    name: "Firefly",
    imageSrc: characterImages["./Firefly.png"],
    path: pathImages[pathMap.DESTRUCTION],
    type: typeImages[typeMap.FIRE],
    region: regionImages[regionMap.STELLARON_HUNTERS],
    traceOne: traceImages[traceMap.TATTERS_OF_THOUGHT],
    traceTwo: traceImages[traceMap.BORISIN_TEETH],
  });

  characterList.push({
    key: index++,
    name: "Jade",
    imageSrc: characterImages["./Jade.png"],
    path: pathImages[pathMap.ERUDITION],
    type: typeImages[typeMap.QUANTUM],
    region: regionImages[regionMap.IPC],
    traceOne: traceImages[traceMap.DREAM_COLLECTION_COMPONENT],
    traceTwo: traceImages[traceMap.ROUGH_SKETCH],
  });
};
loadCharacters();

export { characterList, pathMap, traceMap, typeMap };
