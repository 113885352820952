import React, { useState, useEffect } from "react";
import "./css/Table.css"; // Import the CSS file for styling

const TableRow = ({ data, answer, restartGame }) => {
  const [currentCellIndex, setCurrentCellIndex] = useState(0);

  useEffect(() => {
    let rotationInterval;

    if (data && currentCellIndex < 7) {
      rotationInterval = setInterval(() => {
        setCurrentCellIndex((prevIndex) => (prevIndex + 1) % 8);
      }, 200);
    }

    if (restartGame) {
      setCurrentCellIndex(0);
    }

    return () => {
      clearInterval(rotationInterval);
    };
  }, [data, currentCellIndex, restartGame]);

  const getBackground = (param) => {
    if (data && data[param]) {
      return data[param] === answer[param] ? "correct" : "wrong";
    }
    return "wrong";
  };

  return (
    <tr className="row">
      <td
        className={`table-cell name-cell ${currentCellIndex === 1 ? "rotate" : ""} ${
          currentCellIndex >= 1 && getBackground("name")
        }`}
      >
        <div className="custom-name">{data && currentCellIndex >= 1 ? data.name : ""}</div>
      </td>
      <td
        className={`table-cell image-cell ${currentCellIndex === 2 ? "rotate" : ""} ${
          currentCellIndex >= 2 && getBackground("name")
        }`}
      >
        {data && <img src={data && currentCellIndex >= 2 ? data.imageSrc : ""} className="image" alt="" />}
      </td>
      <td
        className={`table-cell image-cell ${currentCellIndex === 3 ? "rotate" : ""} ${
          currentCellIndex >= 3 && getBackground("path")
        }`}
      >
        {data && <img src={data && currentCellIndex >= 3 ? data.path : ""} className="image" alt="" />}
      </td>
      <td
        className={`table-cell image-cell ${currentCellIndex === 4 ? "rotate" : ""} ${
          currentCellIndex >= 4 && getBackground("type")
        }`}
      >
        {data && <img src={data && currentCellIndex >= 4 ? data.type : ""} className="image" alt="" />}
      </td>
      <td
        className={`table-cell image-cell ${currentCellIndex === 5 ? "rotate" : ""} ${
          currentCellIndex >= 5 && getBackground("region")
        }`}
      >
        {data && <img src={data && currentCellIndex >= 5 ? data.region : ""} className="image" alt="" />}
      </td>
      <td
        className={`table-cell image-cell ${currentCellIndex === 6 ? "rotate" : ""} ${
          currentCellIndex >= 6 && getBackground("traceOne")
        }`}
      >
        {data && <img src={data && currentCellIndex >= 6 ? data.traceOne : ""} className="image" alt="" />}
      </td>
      <td
        className={`table-cell image-cell ${currentCellIndex === 7 ? "rotate" : ""} ${
          currentCellIndex >= 6 && getBackground("traceTwo")
        }`}
      >
        {data && <img src={data && currentCellIndex >= 7 ? data.traceTwo : ""} className="image" alt="" />}
      </td>
    </tr>
  );
};

export default TableRow;
