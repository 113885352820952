import React from "react";
import "./css/LoseModal.css";

const LoseModal = ({ correctCharacter, onClose, onRestart }) => {
  const handleRestart = () => {
    onRestart();
  };

  return (
    <div className="lose-modal-overlay">
      <div className="lose-modal">
        <h2>Game Over</h2>
        <p>You have failed to guess the correct character.</p>
        <p>The correct character was: {correctCharacter.name}</p>
        <img src={correctCharacter.imageSrc} alt="Character of correct answer"></img>
        <br></br>
        <button onClick={handleRestart}>Restart Game</button>
      </div>
    </div>
  );
};

export default LoseModal;
