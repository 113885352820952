// NavBar.js

import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HelpModal from "./HelpModal";
import "./css/NavBar.css";

const NavBar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <AppBar className="customAppBar">
        <Toolbar>
          <Typography variant="h6" component="div" className="customTypography">
            Menu
          </Typography>
          <div className="leftElements">
            <Button className="helpButton" onClick={handleOpen}>
              How to play
            </Button>
            v1.2
          </div>
        </Toolbar>
      </AppBar>
      <HelpModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default NavBar;
